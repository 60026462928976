import { LAMPORTS_PER_SOL } from "@solana/web3.js";

const SOL_DECIMALS = 5;

export const getSolUiAmount = rawAmount => (rawAmount / LAMPORTS_PER_SOL).toFixed(SOL_DECIMALS);

export const getSolRawAmount = uiAmount => parseInt(LAMPORTS_PER_SOL * uiAmount);

export const getTokenRawAmount = (uiAmount, decimals = 6) => uiAmount * 10 ** decimals;

export const getTokenUiAmount = (rawAmount, decimals = 6) => rawAmount / 10 ** decimals;
