import { ErrorMessage as RHFErrorMessage } from "@hookform/error-message";
import classNames from "classnames";

// Styles
import styles from "./ErrorMessage.module.scss";

const ErrorMessage = ({ errors, name, className }) => {
  return (
    <RHFErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <p
          className={classNames({
            [styles.error]: true,
            [className]: !!className,
          })}
        >
          {message}
        </p>
      )}
    />
  );
};

export default ErrorMessage;
