import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  solBalance: null,
  tokens: [],
  targetToken: {},
  logs: [],
  isLoaded: false,
};

const connectSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSolBalance: (state, action) => {
      state.solBalance = action.payload;
    },
    setTokens: (state, action) => {
      state.tokens = action.payload;
    },
    setTargetToken: (state, action) => {
      state.targetToken = action.payload;
    },
    setLogs: (state, action) => {
      state.logs = action.payload;
    },
    setIsUserDataLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    clearUserData: () => initialState,
  },
});

export const {
  setSolBalance,
  setTokens,
  setTargetToken,
  setLogs,
  setIsUserDataLoaded,
  clearUserData,
} = connectSlice.actions;

export default connectSlice.reducer;
