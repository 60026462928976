import { Button as AntButton } from "antd";

const Button = ({ children, type, htmlType, ...props }) => {
  return (
    <AntButton type={type} htmlType={htmlType} {...props}>
      {children}
    </AntButton>
  );
};

export default Button;
