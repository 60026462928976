import { createSlice } from "@reduxjs/toolkit";
import { NETWORK_ENDPOINT } from "../../config";

const initialState = {
  provider: null,
  connection: null,
  endpoint: NETWORK_ENDPOINT,
};

const connectSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {
    setProvider: (state, action) => {
      state.provider = action.payload;
    },
    setConnection: (state, action) => {
      state.connection = action.payload;
    },
  },
});

export const { setProvider, setConnection } = connectSlice.actions;

export default connectSlice.reducer;
