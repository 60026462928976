import { configureStore } from "@reduxjs/toolkit";
import connectSlice from "./reducers/connectSlice";
import userSlice from "./reducers/userSlice";

export const store = configureStore({
  reducer: {
    connect: connectSlice,
    user: userSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "connect/setProvider",
          "connect/setConnection",
          "user/setTokens",
          "user/setTargetToken",
        ],
        ignoredPaths: ["connect.provider", "connect.connection", "user.tokens", "user.targetToken"],
      },
    }),
});
