import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";

import { getBalancesAndLogs } from "../utils/balance";
import { getInitialConnection } from "../utils/connect";

export const useInitialization = () => {
  const dispatch = useDispatch();
  const wallet = useWallet();
  const provider = useSelector(s => s.connect.provider);
  const connection = useSelector(s => s.connect.connection);

  useEffect(() => {
    dispatch(getInitialConnection(wallet));
  }, [dispatch, wallet]);

  useEffect(() => {
    if (wallet.connected && provider && connection) {
      dispatch(getBalancesAndLogs());
    }
  }, [connection, dispatch, provider, wallet.connected]);
};
