import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { WalletDisconnectedError } from "@solana/wallet-adapter-base";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";

// Actions
import { clearUserData } from "../../store/reducers/userSlice";

// Config
import { NETWORK } from "../../config/main";

const SolanaProvider = ({ children }) => {
  const dispatch = useDispatch();
  const endpoint = useSelector(s => s.connect.endpoint);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network: NETWORK }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network: NETWORK }),
      new SolletExtensionWalletAdapter({ network: NETWORK }),
    ],
    [],
  );

  const handleProviderError = useCallback(
    error => {
      if (error instanceof WalletDisconnectedError) {
        notification.warning({
          placement: "bottomLeft",
          message: "You are disconnected",
        });
        dispatch(clearUserData());
      }
    },
    [dispatch],
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect onError={handleProviderError}>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default SolanaProvider;
