import { clusterApiUrl, PublicKey } from "@solana/web3.js";
import { NETWORK, RECEIVER_ADDRESS } from "./main";

export const receiverPk = new PublicKey(RECEIVER_ADDRESS);
export const NETWORK_ENDPOINT = clusterApiUrl(NETWORK);
export const COMMITMENT = "processed";

// for calculating maximumSolAmount considering fee etc.
export const SOL_MAX_DELTA = 0.1;

export { TOKEN_NAMES } from "./tokenNames";
export { TARGET_TOKEN_ADDRESS, PROGRAM_ID } from "./main";
